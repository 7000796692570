/*
  GLOBALS
*/

@use '../colors.scss';

* {
  font-family: "Chakra Petch";
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.5px;

  ::-moz-selection { /* Code for Firefox */
    color: #fff;
    background: colors.$orange-color;
  }
  
  ::selection {
    color: #fff;
    background: colors.$orange-color;
  }

}
html {
  background-color: colors.$text-dark;
}
h1 {
  $breakpoints:(
    'min-width: 900px': 80px,
    'min-width: 600px': 70px,
    'min-width: none' : 40px
  );

  @each $key,$val in $breakpoints{
    @media (#{$key}) { 
          font-size: $val !important;
    }
  }
  margin-left: none;
  margin-bottom: 20px !important;

  @media only screen and (min-width: 600px) {
    margin-left: -15px;
  }
}

.sec-light {
  h1 {
    color: #000;
  }
}
@media only screen and (min-width: 600px) {

.sec .sub {
  font: "Chakra Petch";
  margin-top: -15px !important;
  margin-bottom: 20px;
  font-size: 20px;
}
}

  @media only screen and (max-width: 599px) {
    .sub {
      margin-top: -10px !important;
      margin-bottom: 20px;
    }
  }
.cursive {
  font-family: "CursiveSans";

  //https://stackoverflow.com/questions/58097842/is-it-possible-to-loop-through-multiple-media-queries-with-a-sass-for-loop
  $breakpoints:(
    "50": 'min-width: 900px',
    "30": 'min-width: 600px',
    "20": 'min-width: none'
  );

  @each $key,$val in $breakpoints{
    @media (#{$val}) { 
          font-size: $key + "px";
    }
  }
}

@font-face {
  font-family: "CursiveSans";
  src: url("./CursiveSans-Bold.ttf");
}

@keyframes slide-in {
  0% {
    transform: translateY(10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/*
  NAV
*/
nav {
  .navbar-toggler-icon {
    color: #fff;
  }
  .nav-link {
    text-transform: uppercase;
    padding: 0px 15px !important;
    color: #fff !important;
    font-family: Chakra Petch;
    font-weight: '300';
  }
  .nav-link.active {
    color: colors.$light-gray !important;
  }
  .logo {
    height: 120px;
    position: fixed;
    top: 20px;
    left: 25px;
    transition: 0.1s;
    z-index: 10;
  }
  .btn.btn-primary {
    background-color: colors.$button-color;
    border: none;
    border-radius: 40px;
    height: 45px;
    padding: 0px 40px;
  }

  .logo-collapse {
    opacity: 0;
    transform: scale(0%,0%)
  }
}
.navbar-sticky {
  position: fixed !important;
  transition: 0.2s;
}
.navbar-sticky-collapsed {
  transform: translateY(-100%);
  opacity: 0;
}

@media only screen and (max-width: 991px) {
  .collapse.show, .collapsing {
    height: 100vh !important;
  }
  .collapsing {
    transition: 0.2s;
  }
  .collapse:not(.show) {
    height: 0 !important;
    opacity: 0 !important; 
  }
  .collapse:not(.show) {
    .navbar-nav {
      opacity: 0; 
    }
    transition: 0.2s;
  } 
  .navbar-nav {
    transition: 0.2s;
  }
  .navbar-sticky-collapsed {
    opacity: 1 !important;
    transform: none;
  }
  .nav-item {
    font-size: 25px;
  }
}
@media only screen and (min-width: 991px) {
  nav.navbar {
    height: 80px;  
    width: 100%;
    position: absolute;
  }
}
nav.navbar {
  background-color: rgba(0,0,0,0.8) !important;
  z-index: 9;
  width: 100%;
  backdrop-filter: blur(5px);
}
.dropdown-menu {
  margin-top: 28px;
}
/****
  COMPONENTS
****/

.btn.btn-primary {
  background-color: colors.$button-color;
  border: none;
  border-radius: 40px;
  height: 45px;
  text-transform: uppercase;
  padding: 0px 60px;
  margin-top: 10px;
  font-weight: bold;
}

.sec {
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
  height: 800px;
  overflow: hidden;
}
.sec-stuffed {
  height: auto !important;
}
.sec-light {
  background-color: colors.$light-gray;
  color: colors.$text-dark;
}
.sec-dark {
  color: colors.$light-gray;
}

.sec .col-md-6 img {
  width: 100%;
}

.sec-footer {
  background: #282827;
  height: 200px;
}
.sec-no-bg {
  background-color: transparent;
}
@media only screen and (max-width: 599px) {
  .sec p, .sec h1, .sec h3{
    padding: 0% 10% 0% 10% !important;
  }
  .col-md-6 img{
    position: relative;
    top: 80px;
    width: 100vw;
  }
}
//TICKER
.ticker-wrapper {
  position: relative;
  height: 50px;
  overflow: hidden;
}
@media only screen and (max-width: 1019px) {
   .ticker-wrapper p {
    white-space: nowrap;
    animation-name: ticker;
    animation-iteration-count: infinite;
    animation-duration: 20s;
    animation-timing-function: linear;
    position: absolute;
    top: 1px;
  }
  .ticker-wrapper p::after {
    content: " | "
  }
  .ticker-wrapper p:nth-child(2) {
    white-space: nowrap;
    animation-delay: -10s !important;
    animation-name: ticker;
    animation-iteration-count: infinite;
    animation-duration: 20s;
    animation-timing-function: linear;
    position: absolute;
    top: 1px;
  }
}


@media only screen and (min-width: 1020px) {
  .ticker-wrapper p:nth-child(2) {
    display: none;
  }
}

@keyframes ticker {
  0% {
    transform: translateX(101%);
  }
  100% {
    transform: translateX(-100%);
  }
}


.lightbox {
  height: 300px;
  width: 300px;
  position: relative;
  overflow: hidden;
  margin-bottom: 40px;

  .lightbox-img {
    height: 100%;
    width: 100%;
    position: absolute;
    background-size: cover;
    transition: 0.2s ease-in-out;
    z-index: 0;
  }
  .lightbox-inner {
    z-index: 1;
    color: #fff;
    position: relative;
    height: 100%;
  }
  .lightbox-inner-content {
    transition: 0.2s;
    padding: 40px 20px;
  }

  .lightbox-img {
    transform: scale(1.1);
  }
}
.lightbox:hover {
  .lightbox-inner .lightbox-inner-content {
    transform: translateY(0px);
    opacity: 1;
  }
  
  .lightbox-img {
    filter: blur(1px);
    transform: scale(1);
  }

  .lightbox-inner {
    background-color: rgba(0,0,0,0.4)
  }
}

.lightbox:not(:hover) .lightbox-inner  .lightbox-inner-content {
  transform: translateY(10px);
  opacity: 0;
}

