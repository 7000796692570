@use '../colors.scss';

.gear-title {
    color: colors.$orange-color;
    font-weight: bold;
}
.gear-subtitle {
    font-family: "source-sans-pro", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.quantity {
    font-family: "source-sans-pro", sans-serif;
    font-weight: 300;
    color: #000;
    margin-right: 5px;
}
.model {
    font-family: "source-sans-pro", sans-serif;
    font-weight: 400;
    margin-left: 5px;
    font-size: 26px;
}