.sec-about-hero {
    background-image: url('../about/recor.JPEG');
    background-size: cover;
    background-position: 100% 100%;
}

.sidebox-wrap {
    height: 75vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sidebox-wrap:not(:first-child) {
    margin-top: -20px;
}
.sidebox {
    width: 100%;
    .sidebox-inner {
        padding: 20px;
        height: 100%;
        
        .sidebox-title {
            font-size: 55px;
            font-weight: bold;
            margin-bottom: 20px;
            text-transform: uppercase;
        }
        .sidebox-copy {
            font-size: 20px;
        }
        div {
            height: 85%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
        .sidebox-img {
            height: 100%;
            position: relative;
        }
    }
    height: 80%;
}

.sidebox-wrap .sidebox {
    padding-left: 90px;
    .sidebox-img {
        right: -40px;
    }
}

@media only screen and (max-width: 930px) {
    .sidebox .sidebox-inner .row {
       display: flex;
       flex-direction: column;
       flex-wrap: wrap;
       padding: 100px 0px 40px 0px; 
    }
    .sidebox .sidebox-inner .row > * {
        width: 100%;
    }
    .sidebox-wrap {
        height: auto;
    }
    .sidebox-img {
        width: 100%;
        position: relative;
        left: 0px;
        margin-top: 20px;
    }
}
@media only screen and (max-width: 545px) {
    .sidebox-title {
        font-size: 40px !important;
    }
    .sidebox-inner {
        padding-bottom: 0px !important;
    }
}