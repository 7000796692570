@use '../colors.scss';

.hero {
    background-image: url('hero-studio-splash.png');
    background-size: cover;
    color: #fff; 
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  
  .hero .logo {
    height: 320px;
    min-height: 100px;
    margin-top: 150px;
    margin-bottom: 50px;
    transition: 0.2s ease-in-out;
  }
  @media only screen and (max-width: 1000px) {
    .hero .logo {
      height: auto;
      width: 70%;
      max-width: 450px;
    }
  }
  .hero .logo:not(.logo-collapse) {
    transform: translateY(10%);
    opacity: 0;
  }
  
  .hero p {
    font-family: Chakra Petch;
    font-weight: 500;
    font-size: 28px;
  }
  
  .hero .down-arrow {
    margin-top: 10px;
    height: 60px;
  }
  
  .hero-bottom-black {
    background-color: rgba(0,0,0,0.5);
    padding-top: 30px !important;
    padding-bottom: 150px !important;
    flex-grow: 1;
  }
  
  .hero-sec {
    height: calc(100vh);
    min-height: 800px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 190px;
  }
  .hero .hero-bottom-black {
    padding-bottom: 40px !important;
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
  
  @media only screen and (max-width: 1019px) {
    .hero .hero-bottom-black {
      padding-bottom: 40px !important;
    }
  }
  
  // SECTIONS
  
  .console-sec {
    text-align: left;
    background-image: url(../home/space_bg.JPEG);
  }
  
  .tqr-sec {
    font-size: 20px;
    background-image: url(../home/console-bg.JPG)
  }
  .console-sec h3 {
    font-size: 18px;
    font-family: "Chakra Petch";
    font-weight: 300;
  }
  
  
  .carousel {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 80px;
    overflow: hidden;
  }
  .carousel img {
    height: 20vw;
    width: 20vw;
    flex-shrink: 0;
  }
  
  
  @media only screen and (max-width: 1400px) {
    .col-md-4 {
      width: 100% !important;
    }
  }
  
  @media only screen and (max-width: 1200px) {
    .carousel img {
      height: 80vw;
      width: 80vw;
      flex-shrink: 0;
      margin-left: 10vw;
      margin-right: 10vw;
    }
  }
  
  @media only screen and (max-width: 575px) {
    .carousel img {
      height: 95vw;
      width: 95vw;
      flex-shrink: 0;
      margin-left: 2.5vw;
      margin-right: 2.5vw;
    }
  }
  
  .services-sec {
    background-image: url(mic-bg.jpg);
    padding-top: 0;
    padding-bottom: 0;
    background-position: 30% 30%;
  }
  
  .services-sec .feature img {
    height: 60px;
  }
  
  .black-box {
    background-color: rgba(0,0,0,0.5);
  }
  .white-box {
    background-color: rgba(colors.$light-gray, 0.6);
  }
  .black-box, .white-box {
    box-sizing: content-box;
    padding-top: 100px;
    height: 100%;
    backdrop-filter: blur(3px);
  }
  
  .py-0 .black-box, .py-0 .white-box {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .services-sec h3 {
    margin-top: 5px;
    font-weight: bold;
    font-size: 30px;
  }
  
  .sec-proj {
    background-image: url(../global/mark-room.JPG)
  }
  